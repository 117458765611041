<template>
  <div style="position: relative" class="h-100" v-if="projetTexte">
    <div v-if="route == null" class="w-100 h-100 d-flex d-flex-tel" id="flex-reverse-click-droite" style="position: absolute; top: 0%; right: 0%">
      <div
        style="flex-basis: 50%"
        id="gauche"
        class="h-100 d-flex flex-column align-items-center justify-content-center voile p-3 text-center"
        @click="
          () => {
            afficherProjets('perso');
          }
        "
      >
        <b class="titre align-middle">{{projetTexte.titres.projetPerso}}</b>
        <div class="mt-3">
          <span class="titre badge badge-light">{{ nbProjetsPerso }}</span>
        </div>
      </div>
      <hr v-if="afficherDroite == null" />
      <div
        style="flex-basis: 50%"
        class="h-100 d-flex flex-column align-items-center justify-content-center voile p-3 text-center"
        @click="
          () => {
            afficherProjets('pro');
          }
        "
        id="droite"
      >
        <b class="titre">{{projetTexte.titres.projetPro}}</b>
        <div class="mt-3">
          <span class="titre badge badge-light">{{ nbProjetsPro }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./projet.index.js"></script>

<style scoped>
.titre {
  font-size: 300%;
  animation-name: grossir;
  animation-duration: 1s;
  animation-timing-function: ease;
  transition: all 1s;
}

@media (min-width: 600px) {
  hr {
    height: 100%;
    width: 4px;
    border-top: 0px !important;
  }

  .voile:hover .titre {
    font-size: 400%;
  }
}
@media (max-width: 600px) {
  hr {
    width: 100%;
  }
}

hr {
  color: #000;
  background-color: #000;
  margin: 0%;
}

.voile {
  background-color: rgba(71, 159, 173, 0.43);
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  transition: all 1s;
  z-index: 100;
}

.voile:hover {
  background-color: rgba(71, 159, 173, 0.9);
}

.voile-autre {
  flex-basis: 0% !important;
}

.voile-autre-timeout {
  display: none !important;
}

.voile-clique {
  width: 100%;
  flex-basis: 100% !important;
}

.voile-vers-haut {
  transform: translateY(-100%);
}

.projet {
  animation-name: grossir;
  animation-duration: 1s;
  animation-timing-function: ease;
  transition: all 1s;
}

@keyframes grossir {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
</style>
