import communLangage from '@/commun/commun.langage.js';
export default {
     name: 'ProjetsIndex',

     data() {
          return {
               projetEnCours: null,
               afficherDroite: null,

               projets: []
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          },

          
          projetTexte(){
               return communLangage.projetTexte;
          },

          route() {
               return this.$route.path.split("/")[2] ?? null;
          },

          projetsAAfficher(){
               if(this.route == null) return this.projetTexte.projets;
               return this.projetTexte.projets.filter(x => x.type == this.route);
          },

          nbProjetsPerso(){
               return this.projetTexte.projets.filter(x => x.type == "perso")?.length;
          },

          nbProjetsPro(){
               return this.projetTexte.projets.filter(x => x.type == "pro")?.length;
          }
     },

     methods: {

          afficherProjets(type) {
               const maPromesse = new Promise((resolve) => {
                    var id1 = type == "perso" ? "droite" : "gauche";
                    var id2 = type == "perso" ? "gauche" : "droite";

                    document.getElementById(id1).classList.add("voile-autre");

                    setTimeout(() => { document.getElementById(id1).classList.add("voile-autre-timeout"); if (id2 == "droite") document.getElementById('flex-reverse-click-droite').style.flexDirection = "row-reverse" }, 400);
                    document.getElementById(id2).classList.add("voile-clique");

                    setTimeout(() => { document.getElementById(id2).classList.add("voile-vers-haut") }, 900);
                    setTimeout(() => { resolve() }, 1700);

               })
               maPromesse.then(() => {
                    this.$router.push({name:`projets.type`,params:{type:type}});
               });


          }
     },

     
     created() {

     }
}